<template><!-- App.vue -->

  <v-app>
    <v-app-bar app>
      <VSpacer/>
      <v-avatar color="accent">C</v-avatar>
    </v-app-bar>

    <TheNavigation/>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      &copy; Dline B.V. 2020
    </v-footer>
  </v-app>
</template>

<script>

import TheNavigation from '@/components/TheNavigation.vue';

export default {
  name: 'template-default',
  components: {TheNavigation},
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>