<template>
  <v-navigation-drawer app permanent>
    <router-link :to="{ name: 'Dashboard' }">
      <VImg :src="require('@/assets/logo.png')" class="mt-4 mb-4" contain
            max-height="45"/>
    </router-link>

    <v-list nav>
      <v-list-item-group
          active-class="accent--text text--accent-4"
      >
        <v-list-item :to="{ name: 'Dashboard' }">
          <v-icon>mdi-car-cruise-control</v-icon>
          <VSpacer class="nav-spacer"/>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'SaleGeneral' }">
          <v-icon>mdi-chart-bar</v-icon>
          <VSpacer class="nav-spacer"/>
          <v-list-item-title>Verkoop</v-list-item-title>
          <v-list-item-subtitle>Alle producten</v-list-item-subtitle>
        </v-list-item>

        <v-list-item :to="{ name: 'ShopsGeneral' }">
          <v-icon>mdi-storefront</v-icon>
          <VSpacer class="nav-spacer"/>
          <v-list-item-title>Shops</v-list-item-title>
        </v-list-item>

        <VDivider class="mt-12 mb-12"/>

        <v-list-item @click="logout">
          <v-icon style="transform: rotate(180deg)">mdi-logout</v-icon>
          <VSpacer class="nav-spacer"/>
          <v-list-item-title>Uitloggen</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'TheNavigation',

  methods: {
    logout: function () {
      this.$store.dispatch('Authorisation/logout');
    },
  },

};
</script>

<style scoped>
.nav-spacer {
  width: 8px;
}
</style>
